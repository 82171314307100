@layer components {
    .section-title {
        @apply flex justify-between items-center mb-4 flex-wrap;
    }
    .section-title > * {
        @apply mb-0;
    }
    .section-title--bordered {
        @apply border-b pb-4 border-b-border;
    }
    .section-title--centered {
        @apply justify-center;
    }
    .section-title--with-form {
        @apply flex-wrap;
        > :last-child { @apply min-w-[300px] flex justify-end; }
    }

    .section-title__action {
        @apply text-normal;
    }

    .section-title--bold {
        @apply font-bold
    }

    .section-title--compact {
        h1, h2, h3, h4, h5, h6 {
            @apply mb-2;
        }
    }
}
