@layer components {
    .password-help {
        @apply inline-flex gap-2 md:ml-2;
    }
    .password-help > li::before {
        @apply mr-1 bg-muted rounded-full text-light p-[2px] text-center text-[.7rem];
        content: var(--icon-ti-check-filled);
        font-family: var(--icon-ti-font-family);
    }
    .password-help > li.password-help__success::before {
        @apply bg-success;
    }
}
