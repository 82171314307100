@layer components {
  .link-group {
    @apply flex gap-3 items-end;

    a {
      @apply inline;
    }

    lbs-button {
      @apply flex;
    }
  }
}
