@layer components {
    .header {
        @apply text-light bg-header z-40 relative;
        @apply flex flex-col gap-6 xl:block;
    }
    .header a:not(.button),
    .footer a:not(.button) {
        @apply text-light hover:text-header-hover focus:text-header-hover;
    }

    .header__main {
        @apply pt-5 px-4 xl:pb-5 xl:pt-10 xl:px-side;
        @apply flex justify-between items-center flex-row;
    }
    .header__bar {
        @apply bg-primary text-sm text-header-muted py-[.7rem] flex justify-between px-side;
        @apply hidden lg:block;
    }

    .header__bar .button__secondary-link {
        @apply p-0 pointer-events-none;
    }

    .header__search {
        @apply w-full xl:w-1/2;
    }
    .header__main .header__search {
        @apply hidden xl:block;
    }


    .header__logo {
        @apply h-[60px] w-[96px];
    }

    .header__logo__img {
        @apply h-full
    }

    .header__buttons {
        @apply flex gap-6 xl:gap-8 items-center;
    }
    .header__buttons__group {
        @apply flex gap-6;
    }
    .header__buttons__group a {
        @apply flex items-center;
    }

    .header__navigation-container {
        @apply px-4 pb-5 xl:pb-0 xl:px-side flex gap-8 justify-between relative;
    }
    .header__navigation {
        @apply w-full;
    }
    .header__navigation .header__search {
        @apply block w-full xl:hidden;
    }
    .menu-toggler__container {
        @apply items-center gap-2 flex;
        span { @apply inline-flex; }
    }
}
