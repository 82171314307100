@layer components {
    .filter-list {
        @apply flex overflow-x-hidden md:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-5 gap-4 flex-wrap;
        @apply mb-paragraph relative max-xl:mt-8;

        &.filter-list--scroll-left:before {
            content: "";
            @apply bg-gradient-to-l from-transparent to-light absolute top-0 left-0 bottom-0 w-[2rem] md:hidden;
        }
        &.filter-list--scroll-right:after {
            content: "";
            @apply bg-gradient-to-r from-transparent to-light absolute top-0 right-0 bottom-0 w-[2rem] md:hidden;
        }
    }

    ul.filter-list .form-control--filter,
    ul.filter-list .form-group,
    ul.filter-list .form-control,
    ul.filter-list .form-control-group {
        @apply w-fit md:w-full;
    }

    .filter-list--4 {
        @apply grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4;
    }
    .filter-list--purchases {
        @apply grid;
        > * { @apply w-full lg:col-span-1; }
        > *:first-child { @apply md:col-span-2 lg:col-span-3; }
    }

    .filter-list__more.form-control.form-control--filter {
        @apply bg-light hover:bg-grey-100;
    }
    .filter-list__more a {
       @apply flex justify-between items-center w-full text-primary;
    }
    .filter-list__more__label {
        @apply hidden md:inline;
    }
}
