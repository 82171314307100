@layer components {
    .progress-container {
        @apply relative flex justify-around items-center w-full;
    }

    .progress__step__container {
        @apply flex flex-col items-center gap-[.4rem] max-md:w-[70px] w-[80px];
    }


    .progress__step__container p {
        @apply max-md:text-sm max-md:mb-0;
    }

    .progress__step {
        @apply w-10 aspect-square flex justify-center items-center border-2 relative border-border bg-light rounded shadow-whiteBorder;
    }

    .progress__step.active {
        @apply border-valid;
    }

    .progress-bar {
        @apply absolute bg-border h-0.5 w-full rounded-full top-5; /* top-5 is half the height of the progress__step__container */
        @apply -z-10;
    }

    .progress-bar .progress-bar__indicator {
        @apply absolute h-full bg-valid rounded-full;
    }

    .checkout-progress-items {
        @apply flex flex-wrap gap-y-2 gap-x-8 justify-center mb-8;
    }
}