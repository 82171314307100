@layer components {
    .account-form__header {
        @apply text-center border-b border-border mb-16;

        h1, h2, h3, h4, h5, h6 {
            @apply mb-paragraph;
        }
    }

    .account-form-inline {
        @apply flex flex-wrap justify-between gap-8;

        &:not(.account-form-inline--open) {
            .account-form-inline__form,
            .account-form-inline__help {
                @apply hidden;
            }
        }
        .account-form-inline__form {
            @apply mt-paragraph;

            .form-group {
                @apply mb-0;
            }
        }
        .account-form-inline__help {
            @apply text-muted text-tiny;
            p { @apply mb-2; }
        }
    }
}