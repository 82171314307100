@layer components {
  .footer {
    @apply bg-header text-light;
  }

  .footer a {
    @apply text-light hover:text-light-highlight focus:text-light-highlight;
  }

  .footer__section {
    @apply px-4 md:px-side;
  }

  .footer__section--main {
    @apply text-sm pt-8 pb-4 gap-0 md:gap-8;
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5;
  }

  .footer__section--main > * {
    @apply mb-8;
  }

  .footer__section--bottom {
    @apply text-tiny py-4 px-side gap-8 bg-primary;
    @apply grid grid-cols-1 lg:grid-cols-3;
  }

  .footer__section--bottom p {
    @apply mb-0;
  }

  .footer__section--top {
    @apply pt-5 md:pt-14 pb-4 gap-8 md:border-b md:border-solid md:border-border;
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5;
  }

  .menu-collapsable {
    @apply mb-0 border-b border-border md:border-b-0;
  }

  .menu-collapsable button {
    @apply flex flex-row justify-between items-center md:items-start w-full;
  }

  .menu-collapsable button > * {
    @apply max-md:mb-0 max-md:py-2;
  }

  .menu-collapsable ul {
    @apply hidden md:flex;
  }

  .menu-collapsable.menu--open ul {
    @apply flex;
  }

  .footer__menus {
    @apply grid grid-cols-1 md:grid-cols-3 md:col-span-3;
  }
}
