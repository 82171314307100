@layer components {
    .pagination-container {
        @apply flex gap-6 justify-center mt-5;
    }
    .pagination {
        @apply flex items-center justify-center text-border gap-3;
    }
    .pagination a {
        @apply text-primary hover:text-accent focus:text-accent;
    }
    .pagination li,
    .pagination a {
        @apply inline-flex;
    }

    .pagination__current {
        @apply font-bold;
    }

  .pagination-button {
    &.button--disabled {
      @apply text-border hover:text-border;
    }
  }
}
