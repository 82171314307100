@media print {
  .no-print {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }

  .print-underline {
    @apply block h-[16px] w-full border-b border-border;
  }

  .print-no-break {
    break-inside: avoid;
  }
}

.print-only {
  display: none;
}
