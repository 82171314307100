@layer components {
    .product-preview--variants {
        @apply flex-row flex-wrap justify-between items-end;
    }

    .product-preview--variants .product-preview__container {
        @apply flex gap-4;
    }

    .product-preview--variants .product-preview__availability {
        @apply absolute top-4 md:top-5 right-4 md:right-5 mt-0;
    }

    .product-preview--variants .product-preview__img {
        @apply w-[180px] md:h-[180px] max-md:w-[110px] max-md:h-full;
    }

    .product-preview--variants .product-preview__img img {
        @apply mb-0;
    }

    .product-preview__variants-actions {
        @apply visible flex static gap-x-4 gap-y-1 mt-4;
    }

    /*.product-preview--variants .product-preview__info > .product-preview__hints {
        @apply mt-0;
    }

    .product-preview--variants .product-preview__info > .product-preview__price {
        @apply md:mt-3;
    }*/
}

