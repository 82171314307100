@layer components {
  .arrow {
    @apply flex justify-center absolute top-1/2 -translate-y-1/2 rounded-full bg-grey text-primary transition-all;
    @apply w-6 h-6;

    &:not(.arrow--disabled) {
      @apply hover:text-primary focus:text-primary hover:bg-grey-400 focus:bg-grey-400 hover:shadow-default focus:shadow-default;
    }
  }

  .arrow--left {
    @apply -left-[5px] md:left-[10px] xl:left-0 -translate-x-1/2;
  }

  .arrow--right {
    @apply -right-[5px] md:right-[10px] xl:right-0 translate-x-1/2;
  }
  .arrow--lg {
    @apply w-10 h-10;
  }

  .arrow--disabled {
    @apply cursor-default text-light opacity-70;
  }
}
