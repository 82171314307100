@layer components {
  .product-table-item {
    @apply inline-flex gap-4 items-center flex-wrap;
    span,
    div {
      @apply overflow-hidden overflow-ellipsis;
    }
  }

  .product-table-item > img {
    @apply w-thumbnail h-thumbnail object-contain;
  }
}
