@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?m27g27');
    src:  url('../fonts/icomoon.eot?m27g27#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?m27g27') format('truetype'),
    url('../fonts/icomoon.woff?m27g27') format('woff'),
    url('../fonts/icomoon.svg?m27g27#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.im {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.im-compare:before {
    content: "\e900";
}
.im-rating:before {
    content: "\e901";
}
