@layer components {
  .product-variant-list {
    @apply overflow-x-auto border-b-0 mb-4;

    td {
      @apply px-small #{!important};
    }

    tr:last-child {
      @apply border-b-0;
    }

    .product-variant-row {
      @apply hover:bg-secondary cursor-pointer #{!important};
    }
  }

  .product-variant-list th {
    @apply text-sm font-bold;
  }

  .product-variant-list__header,
  .product-variant-list__list__product {
    @apply px-2;
  }

  .product-variant-list__article-number {
    @apply w-[17%];
  }

  .product-variant-list__size {
    @apply w-[14.5%];
  }

  .product-variant-list__color {
    @apply w-[25%];
  }

  .product-variant-list__unit {
    @apply w-[14.5%] text-right;
  }

  .product-variant-list__availability {
    @apply w-[14.5%] text-right;
  }

  .product-variant-list__price {
    @apply w-[14.5%] text-right;
  }

  .product-variant-list__list__product {
    @apply border-t border-t-border py-3 hover:bg-secondary focus:bg-secondary cursor-pointer;
  }

  .product-variant-list__list__product.product-variant--active {
    @apply hover:bg-transparent focus:bg-transparent cursor-auto;
  }
}
