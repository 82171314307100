@layer components {
  .section {
    @apply mb-8 md:mb-section;
  }
  .section--large {
    @apply mb-section-lg;
  }
  .section--narrow {
    @apply max-w-[500px] w-full mx-auto;
  }

  .section--medium {
    @apply md:w-2/3 mx-auto;
  }

  .section--wide {
    @apply max-w-login mx-auto;
  }

  .marketing-full-width .section--wide {
    @apply max-w-none;
  }

  .section--border {
    @apply border border-border;
  }

  .sections > .section:last-child {
    @apply mb-0;
  }

  .sections--divided {
    @apply divide-solid divide-y divide-border;
  }

  .sections--divided > .section:not(:first-child) {
    @apply pt-section;
  }
  hr.sections--divider {
    @apply my-8 md:my-section;
  }

  .section--total {
    @apply flex justify-between border-y border-dashed border-border py-8;
  }

  .section--total__total {
    @apply text-h3 font-bold;
  }

  .section--total__mwst {
    @apply text-sm font-normal;
  }

  .section--total h2,
  .section--total h3,
  .section--total h4,
  .section--total h5,
  .section--total h6,
  .section--total p {
    @apply mb-0;
  }

  .section--compact {
    @apply mb-8;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply mb-2;
    }
  }
}
