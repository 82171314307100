@layer components {
  .pop-up__container {
    @apply fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center z-50 max-md:p-4 p-16;
  }

  .pop-up__container--side {
    @apply p-0 items-end justify-end;
  }

  .pop-up {
    @apply bg-light p-12 overflow-auto w-full hidden rounded-sm shadow max-h-full;
  }

  .pop-up__container--side .pop-up {
    @apply h-full max-w-popup-side rounded-none;
  }

  .pop-up--visible {
    @apply block;
  }
  .pop-up--big {
    @apply max-w-full xl:max-w-[75%];
  }
  .pop-up--medium {
    @apply max-w-[730px];
  }
  .pop-up--small {
    @apply max-w-[500px];
  }

  .pop-up--side {
    @apply w-full p-0 h-full flex flex-col overflow-hidden;
  }

  .pop-up__title {
    @apply flex justify-between items-start mb-8;
  }
  .pop-up__title h1,
  .pop-up__title h2,
  .pop-up__title h3,
  .pop-up__title h4,
  .pop-up__title h5,
  .pop-up__title h6 {
    @apply mb-0;
  }
  .pop-up__close {
    @apply text-[1.75rem] text-primary mt-2;
  }
}

.cdk-global-overlay-wrapper {
  overflow: scroll !important;
}
