@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

@import "node_modules/tippy.js/dist/tippy.css";
@import "node_modules/tippy.js/themes/light.css";
@import "node_modules/tippy.js/animations/scale.css";

@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";

@tailwind base;
@tailwind components;
@tailwind utilities;

// custom Furter styling
@import "style/theme/index";
@import "style/material-override";

// TIPPY
.tippy-box {
  border-radius: 0;
  background-color: theme("colors.white");
  color: theme("colors.muted");
  border: 1px solid theme("colors.border");
}

.tippy-arrow {
  color: theme("colors.border");
}

.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: -8px;
}

.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -8px;
}

.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -8px;
}

.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  right: -8px;
}

// Spinner-Overlay
.partial-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 0.5vw solid theme("colors.accent-light");
  border-top: 0.5vw solid theme("colors.accent");
  border-radius: 50%;
  animation: spin 2s linear infinite;
  @apply w-[15vw] h-[15vw] sm:w-[5vw] sm:h-[5vw] border-[1vw] sm:border-[0.5vw];
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
