@layer components {
    .details {
        @apply grid grid-cols-1 md:grid-cols-2 my-8;
    }
    .details--account-form {
        @apply mb-0;
    }
    .details__label {
        @apply text-muted text-sm;
    }
    .details__large {
        @apply col-span-2;
    }
}
