@layer components {
    .mobile-menu-container {
        @apply absolute bg-light left-0 right-0 -translate-x-full transition-translate duration-300 ease-in-out;
        @apply xl:hidden;
    }
    .mobile-menu-container[data-visible=true] {
        @apply translate-x-0
    }
    .mobile-menu-container a:not(.button) {
        @apply text-primary #{!important};
    }
}
