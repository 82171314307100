@layer components {
    .menu {
        @apply flex flex-col gap-2 mb-paragraph;
    }

    .menu a,
    .menu .menu__link {
        @apply text-primary cursor-pointer;
    }

    .menu--mobile .menu__item--has-children::after {
        content: var(--icon-ti-chevron-right);
        @apply text-primary;
    }

    .menu--sideways {
        @apply flex-row mb-0;
    }

    .menu--divided {
        @apply block divide-y divide-border;
    }
    .menu--divided:not(.menu--open) {
        @apply border-b border-border;
    }

    .menu--logos img {
        @apply h-8 w-auto;
    }

    .menu--divided li.active a {
        @apply font-bold text-primary;
    }

    .menu--divided li a,
    .menu--divided li .menu__link {
        @apply block -mx-4 px-4 py-4;
    }
    .menu--divided-compact li a,
    .menu--divided-compact li .menu__link {
        @apply py-2;
    }

    .menu--collapsible {
        @apply max-xl:hidden;
    }

    .menu--collapsible.menu--open {
        @apply block mb-10;
    }

    .menu--collapsible li a,
    .menu--collapsible li .menu__link {
        @apply mx-0;
    }

    .mobile-menu-container {
        @apply px-4 py-5;
    }

    .mobile-menu-container > * {
        @apply mb-8;
    }

    .menu__item--has-children {
        @apply relative;

        &:not(.menu__item--opened) {
            a,
            .menu__link {
              @apply relative;
            }

            &:hover a:after,
            &:focus a:after,
            &:hover .menu__link:after,
            &:focus .menu__link:after {
                content: "";
                @apply h-[1px] bottom-[-1px] right-0 left-0 absolute bg-light z-10;
            }
        }

        &.menu__item--opened {
            .menu { @apply pt-1; }
        }
    }
    .menu__item--has-children a,
    .menu__item--has-children .menu__link {
        @apply transition;
    }
    .menu__item--has-children:not(.menu__item--opened):hover a,
    .menu__item--has-children:not(.menu__item--opened):hover .menu__link {
        @apply bg-secondary;
    }
    .menu__item--has-children:after {
        content: var(--icon-ti-caret-right-filled);
        font-family: var(--icon-ti-font-family);
        @apply absolute right-0 top-0 bottom-0 flex items-center text-border text-opacity-40;
    }
    .menu__item--has-children:not(.menu__item--opened) .menu {
        @apply absolute top-0 left-full bg-secondary hidden px-4 w-full max-w-[20rem] transition z-10;
    }
    .menu__item--has-children:hover {
        @apply border-b-transparent;

        .menu {
            @apply block;
        }
    }

    .menu__item--opened .menu {
        @apply ml-4;
    }

    .menu__item--active a,
    .menu__item--active .menu__link,
    .menu__link.active {
        @apply font-bold;
    }
}
