:root {
    --icon-ti-chevron-right: "\ea61";
    --icon-ti-arrow-up-left: "\ea22";
    --icon-ti-caret-right-filled: "\fb2c";
    --icon-ti-circle-check-filled: "\f704";
    --icon-ti-check-filled: "\ea5e";
    --icon-ti-arrow-left: "\ea19";
    --icon-ti-arrow-back-up: "\eb77";
    --icon-ti-check: "\ea5e";
    --icon-ti-circle-half-2: "\eff3";
    --icon-ti-circle-filled: "\f671";
    --icon-ti-alert-circle-filled: "\f6ee";
    --icon-ti-font-family: "tabler-icons" !important;
}
