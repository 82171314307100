@layer components {
    dl.list,
    .list {
        @apply flex flex-wrap mb-4
    }
    dl.list dt,
    dl.list dd {
        @apply w-[50%]
    }
    dl.list dt {
        @apply text-left
    }
    dl.list dd {
        @apply text-right mb-4
    }
    dl.list dd:last-child {
        @apply mb-0
    }
    .list__highlight {
        @apply font-bold
    }
    .list--bordered {
        @apply border-y border-grey-400 py-4
    }

    .list--divided {
        @apply flex-col space-y-2 sm:flex-row sm:divide-border sm:divide-x sm:space-x-4 sm:space-y-0;
    }

    .list--divided > *:not(:first-child) {
        @apply sm:pl-4;
    }

    .list--benefits {
        @apply flex flex-col gap-2;
    }
    .list--benefits-account {
        @apply flex gap-8 justify-center mb-8;
    }
    .list--benefits-simple {
        @apply flex flex-col;
    }

    .list--benefits-account,
    .list--benefits {
        > li {
            @apply inline-flex items-center;
        }

        > li::before {
            @apply text-light bg-valid mr-2 text-center text-[.7rem] rounded-full w-[1rem] h-[1rem] leading-[1rem];
            content: var(--icon-ti-check-filled);
            font-family: var(--icon-ti-font-family);
        }
    }
    .list--benefits-simple {
        > li {
            @apply inline-flex items-center;
        }

        > li::before {
            @apply mr-2 text-center;
            content: var(--icon-ti-check);
            font-family: var(--icon-ti-font-family);
        }
    }

    .list--meta,
    .list--meta-muted {
        @apply mb-paragraph;
    }
    .list--meta-muted {
        li > span { @apply text-muted; }
    }

    .list--disc {
        @apply list-disc ml-6 mb-paragraph;
    }
}
