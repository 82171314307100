@layer components {
  .form-select {
    /* pb-[.63rem] */
    @apply bg-selectArrow bg-no-repeat bg-[length:1.25rem] bg-right-center p-[.63rem] px-[.72rem]
    text-normal bg-light appearance-none rounded border-border border-solid border focus:outline-none focus:ring-0;
    @apply pr-[2.5rem] #{!important};
  }

  .form-select.form-control--unskinned {
    @apply border-none pr-[2rem];
  }

  .form-control.form-control--select {
    @apply rounded bg-light w-full;
  }

  .form-control.form-control--filter {
    @apply rounded-full bg-light-highlight hover:bg-grey-400 focus:bg-grey-400 transition-colors py-1 w-full h-auto text-sm px-4 bg-[length:.875rem];
    @apply pr-[2rem] #{!important};

    &.button {
      @apply pr-[.75rem] #{!important};
    }
  }
}
