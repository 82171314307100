@layer components {
    .accordions .accordion {
        @apply -mx-5 mt-[-1px];
    }

    .accordion__header {
        @apply flex flex-row justify-between items-center py-5 px-5 text-primary hover:bg-light-highlight focus:bg-light-highlight cursor-pointer;
        @apply hover:text-inherit transition-none #{!important};
        @apply border-t border-border mx-5 px-0;
        @apply hover:mx-0 hover:px-5;
    }
    .accordion__content {
        @apply hidden mt-4 mb-8 px-5;
    }
    .accordion--open .accordion__content {
        @apply block;
    }
    .accordion__title {
        @apply mb-0;
    }
    .accordion__icon {
        @apply transition;
    }
    .accordion--closed .accordion__icon {
        @apply rotate-180;
    }
    .accordion--closed .accordion__header {
        @apply border-b;
    }
}
