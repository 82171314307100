@layer components {
    .order-meta {
        @apply border-y border-y-border pt-4;
    }

    .order-meta__order-date span{
        @apply text-muted;
    }

    .order-meta__project span{
        @apply text-muted;
    }

    .order-meta__sum span{
        @apply text-muted;
    }

    .order-meta__state span{
        @apply text-success;
    }
}