@layer components {
  input[type="password"]::-ms-reveal {
    display: none;
  }

  input[type="password"]::-ms-clear {
    display: none;
  }

  .form-control {
    @apply inline-block px-2 text-normal bg-light appearance-none rounded border-border h-[2.625rem]
    border-solid border focus:outline-1 focus:outline-primary focus:ring-0 text-primary placeholder-muted;
  }

  .form-control--zip {
    @apply max-w-[6rem];
  }

  .form-control--block {
    @apply w-full;
  }

  .form-control--valid {
    @apply bg-input-valid bg-no-repeat bg-[length:1.25rem] bg-right-center leading-5;
  }

  .form-control--invalid {
    @apply border-invalid bg-no-repeat bg-[length:1.25rem] bg-right-center leading-5 #{!important};
  }

  .form-control--password {
    @apply bg-password bg-no-repeat bg-[length:1.25rem] bg-right-center leading-5;
  }

  .form-control--disabled {
    @apply bg-light-highlight text-muted pointer-events-none;
  }

  .form-group {
    @apply flex flex-col mb-5 relative;
  }

  .form-group__help {
    @apply text-tiny mt-1 w-full text-muted;
  }

  .form-group__help--link {
    @apply text-sm mt-1 flex justify-end;
  }

  .form-control--limited {
    @apply max-w-[550px];
  }

  .form-control--large {
    .form-control {
      @apply h-[3.125rem];
    }
  }

  .form-group--with-info {
    @apply flex-row gap-4;

    .text-tooltip i {
      @apply text-h2;
    }
  }
}
