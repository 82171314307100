@layer components {
    .category {
        @apply flex flex-col justify-end text-center text-primary py-4 px-8 hover:bg-light-highlight focus:bg-light-highlight;
    }
    .category .category__title {
        @apply overflow-hidden overflow-ellipsis;
    }
    .category .category__img {
        @apply w-full max-w-category max-h-category object-contain mx-auto mb-4 aspect-square;
    }

    .items--categories {
        .category {
            @apply h-full;
        }
    }
}