@layer components {
    .label-sheet {
        @apply relative px-2.5 py-3;
        @apply max-h-full border border-border border-solid;
    }

    .label-sheet ul {
        @apply grid h-full w-full gap-2 auto-rows-fr;
    }

    .label-sheet ul li {
        @apply bg-header-muted text-light;
        @apply inline-flex justify-center items-center transition-all ease-in-out cursor-pointer;
    }

    .label-sheet li.active,
    .label-sheet li:hover {
        @apply bg-accent;
    }

    .label-sheet li.active > span,
    .label-sheet li:hover > span {
        @apply inline-block;
    }

    .label-sheet li span {
        @apply hidden;
    }

    .label-sheet p {
        @apply absolute right-0 bottom-0 translate-x-full translate-y-full -rotate-90 origin-top-left;
        @apply text-grey-100 text-xs mb-0;
    }

    .label-sheet--a4 {
        @apply h-[297px] w-[210px];
    }

    .label-sheet--a4 ul {
        @apply grid-cols-2;
    }
}