@layer components {
  .product-images__full {
    @apply pb-80p h-0 relative mx-auto;

    .arrow {
      @apply invisible;
    }
    &:hover .arrow {
      @apply visible;
    }

    @media (hover: none) {
      .arrow {
        @apply visible #{!important};
      }
    }
  }
  .product-images__full img {
    @apply absolute w-full h-full object-contain;
  }

  .product-images__preview {
    @apply flex flex-row gap-4 mt-7 flex-wrap;
    img {
      @apply w-thumbnail-preview-mobile h-thumbnail-preview-mobile object-contain;
    }
    a {
      @apply transition hover:bg-light-highlight focus:bg-light-highlight;
    }
  }

  @media print {
    .g-box,
    gallery-slider {
      @apply hidden;
    }
  }
}
