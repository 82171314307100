@layer components {
  .button {
    @apply inline-flex items-center justify-center rounded px-[.8rem] py-[.5rem] h-fit w-fit min-h-[2.625rem] leading-normal gap-x-[.6rem] transition-all ease-in-out delay-100;
  }

  .button--outline {
    @apply border border-border hover:border-primary focus:border-primary;

    &.button--disabled {
      @apply bg-light-highlight text-muted pointer-events-none;
    }
  }

  .button.button--small {
    @apply text-sm px-3 py-[.3rem] min-h-0;
  }

  .button.button--icon-only {
    @apply p-2.5;
  }

  .button--primary {
    @apply bg-primary disabled:bg-primary disabled:shadow-none disabled:text-opacity-50 hover:shadow-default text-light hover:bg-primary-button-hover focus:bg-primary-button-hover;
  }

  .button--secondary {
    @apply bg-secondary text-primary disabled:bg-secondary disabled:shadow-none disabled:text-opacity-50 hover:shadow-default hover:bg-grey-400 focus:bg-grey-400;
  }

  .button--transparent {
    @apply bg-none;
  }

  .button--primary-link {
    @apply text-primary px-0 hover:text-primary-hover;
  }

  .button--accent-link {
    @apply text-accent px-0 hover:text-accent-hover;
  }

  .button--secondary-link {
    @apply text-muted hover:text-primary focus:text-primary px-0;

    &.button--disabled {
      @apply hover:text-muted;
    }
  }

  .button--accent {
    @apply bg-accent hover:bg-accent-hover text-light;
  }

  .button--block {
    @apply flex justify-center items-center;
  }

  .button--fill {
    @apply h-full w-full align-middle;
  }

  .button.button__loading {
    @apply pointer-events-none opacity-50 relative;
  }

  // LOADING STATE FOR BUTTON
  .loading-box {
    position: absolute;
    width: calc(100% - 20px);
    height: 2px;
    overflow: hidden;
    display: block;
    bottom: 7px;

    .loader {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      animation: load 1.5s linear infinite;
      @apply bg-light;
    }
  }

  .button.button--secondary,
  .button.button--transparent {
    .loader {
      @apply bg-primary;
    }
  }

  .button.button--accent {
    .loader {
      @apply bg-light;
    }
  }

  @keyframes load {
    0% {
      left: -100%;
      width: 25%
    }
    50% {
      width: 50%
    }
    100% {
      left: 100%;
      width: 25%
    }
  }
}
