@layer components {
  .form-control--radio {
    @apply flex flex-row gap-2 w-full items-start relative;
  }

  .form-control--radio input {
    @apply absolute opacity-0 cursor-pointer h-0 w-0;
  }

  .form-control--radio .checkmark {
    @apply absolute top-1 left-0 h-4 w-4 bg-light rounded-full border-primary;
  }

  .form-control--radio .checkmark:after {
    @apply absolute hidden top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 h-1.5 w-1.5 rounded-full bg-primary;
    content: "";

  }

  .form-control--radio input:checked ~ .checkmark:after {
    @apply block;
  }

  .form-control--radio label {
    @apply w-full ml-8;
  }

  .form-control--radio__label--highlighted {
    @apply font-bold;
  }

  .pay-img {
    @apply w-14 h-6 object-contain border border-border rounded-md;
    //@apply object-contain border border-border rounded-md;
  }
}
