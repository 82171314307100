@layer components {
    .search-form {
        @apply relative;

        .form-control-group__actions {
            @apply right-4;
        }
    }
    .search-form .form-control-group {
        @apply border-0 rounded-large px-5 py-1 md:px-8 bg-light-200;
    }
    .search-form .form-control {
        @apply text-light p-0 bg-transparent border-0 focus:outline-none;
    }

    .search-form .form-control::placeholder {
        @apply text-light;
    }
    .search-form .form-control-group i {
        @apply text-light;
    }

    .search-form.search-form--active {
        @apply bg-light rounded-large-half rounded-b-none;
    }
    .search-form.search-form--active,
    .search-form.search-form--active .form-control,
    .search-form.search-form--active .form-control::placeholder,
    .search-form.search-form--active .form-control-group i {
        @apply text-primary;
    }
}
