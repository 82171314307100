@layer components {
  .brands-list {
    @apply grid grid-cols-4 md:grid-cols-5 grid-flow-row;
  }

  .brands-list li {
    @apply px-2 md:px-8 py-4 h-full flex items-center justify-center;
  }

  .brands-list img {
    @apply w-full;
  }
}
