@layer components {
    .toggle {
        @apply inline-flex gap-4 cursor-pointer justify-end;
    }

    .toggle__switch {
        @apply relative inline-block w-11 h-6;
    }

    .toggle__switch input {
        @apply opacity-0 w-0 h-0
    }

    .toggle__switch__slider {
        @apply absolute top-0 inset-0 bg-grey-100 duration-500 rounded-3xl
    }

    .toggle__switch__slider:before {
        content: '';
        @apply absolute h-4 w-4 left-1 bottom-1 bg-light duration-500 rounded-[50%]
    }

    .toggle__switch input:checked + .toggle__switch__slider {
        @apply bg-accent
    }

    .toggle__switch input:checked + .toggle__switch__slider:before {
        @apply translate-x-5
    }
}
