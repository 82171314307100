@layer components {
    .reviews {
        @apply flex flex-col gap-8 border-b border-b-border pb-8 mb-4;
    }
    .reviews .review {
        @apply border-t border-t-border pt-8;
    }

    .review {
        @apply flex md:items-start justify-between gap-4 flex-col md:flex-row;
    }
    .review p {
        @apply mb-0;
    }

    .review__content {
        @apply max-w-[46rem];
    }

    .review__rating {
        @apply flex-wrap md:flex-none flex items-baseline gap-5;
    }

    .review__image {
        @apply w-full max-w-thumbnail md:max-w-thumbnail-preview-mobile object-contain;
    }
}
