@layer components {
    .form {
        @apply mb-paragraph;
    }

    .form--inline {
        @apply inline-flex gap-4 w-full;

        .form-floating {
            /*@apply w-auto #{!important};*/
        }
    }

    .form--inline > .form-field {
        @apply max-w-full min-w-[21rem];
    }

    .form-actions {
        @apply mt-8;
    }
}
