@layer components {
    .advertisement {
        @apply w-full h-full overflow-hidden flex relative md:min-h-[500px];
    }

    .advertisement__img {
        @apply h-full w-full object-cover transition absolute top-0 right-0 bottom-0 left-0 z-0;
    }

    .advertisement:hover .advertisement__img {
        @apply scale-110;
    }

    .advertisement__text {
        @apply p-8 flex flex-col justify-end text-light relative flex-1;
        background: linear-gradient(45deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 70%);
    }
    .advertisement__text > *:last-child {
        @apply mb-0;
    }
    .advertisement__badges {
        @apply mb-4 md:mb-6;
    }
}
