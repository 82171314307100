.product-preview-variants-list__header {
    @apply border-b-border flex flex-row justify-between
}

.product-preview-variants-list__header__title {
    @apply text-h2;
}

.product-preview-variants-list__container__product{
    @apply border-b-border
}

.product-preview-variants-list__pagination {
    @apply flex justify-center mt-14
}