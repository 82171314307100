@layer components {
    .form-floating {
        @apply relative w-full overflow-hidden;
    }

    .form-floating__input {
        @apply relative;
    }

    .form-floating .form-control {
        @apply px-2 pt-4 pb-0;
    }

    .form-floating .form-control__label {
        @apply max-md:line-clamp-1 absolute max-md:text-sm text-normal text-placeholder z-10 duration-300 transform -translate-y-4 scale-75 top-3 origin-[0] px-2 py-1 left-1 truncate max-w-full;
        @apply pointer-events-none;
    }

    .form-floating__message {
        @apply text-tiny mt-1;
    }
    .form-floating--invalid .form-floating__message {
        @apply text-invalid;
    }
}