@layer components {
  .product-preview {
    @apply hover:bg-light-highlight transition cursor-pointer relative px-5 py-5;
    @apply flex flex-col h-full justify-end;
  }

  .product-preview__container {
    @apply flex flex-col h-full;
  }

  .items--results {
    .product-preview {
      //@apply max-md:gap-4 max-md:py-4 max-md:flex-row;
      @apply max-md:gap-4 max-md:py-4;
    }

    .product-preview__rating {
      @apply max-md:mt-4;
    }

    .product-preview__availability {
      @apply max-md:absolute max-md:right-5 max-md:top-0;
    }
  }

  .items--products.items--carousel .product-preview {
    @apply w-product-preview;
  }

  .product-preview__img {
    @apply max-md:h-thumbnail-preview-mobile h-thumbnail-preview md:pb-paragraph flex justify-center relative pointer-events-none;
  }

  .owl-item .product-preview__img {
    @apply max-md:h-[180px];
  }

  .product-preview__img img {
    @apply object-contain pb-paragraph;
  }

  .product-preview__hints {
    @apply flex gap-2 flex-wrap mb-paragraph max-md:mb-2;
  }

  .product-preview__price {
    @apply font-bold max-md:mb-0;
  }

  .product-preview p {
    @apply line-clamp-2 truncate leading-5 whitespace-normal hyphens-auto break-words;
  }

  .product-preview__rating {
    @apply mt-2;
  }

  .product-preview__rating .rating-container {
    @apply mb-0;
  }

  .product-preview__availability {
    @apply mt-4;
  }

  .product-preview__actions {
    @apply md:opacity-0 md:absolute bottom-5 right-5 items-center ml-auto flex-wrap justify-end transition;
  }

  .product-preview:hover .product-preview__actions {
    @apply opacity-100 flex;
  }

  @media (hover: none) {
    .product-preview__actions {
      @apply opacity-100 flex;
    }
  }
}
