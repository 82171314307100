@import "variables";
@import "tabler-icons";

@import "base/headings";
@import "base/divide";
@import "base/paragraph";
@import "base/icons";
@import "base/icomoon";
@import "base/print";
@import "base/skeleton-loader";

@import "atoms/badge";
@import "atoms/icon";
@import "atoms/form/form";
@import "atoms/form/input";
@import "atoms/form/checkbox";
@import "atoms/form/select";
@import "atoms/form/textarea";
@import "atoms/form/options";
@import "atoms/form/radioButton";
@import "atoms/availability";
@import "atoms/availabilityTooltip";
@import "atoms/list";
@import "atoms/content-separator";
@import "atoms/card";
@import "atoms/section";
@import "atoms/section-title";
@import "atoms/accordion";
@import "atoms/menu";
@import "atoms/alert";
@import "atoms/items";
@import "atoms/table";
@import "atoms/price";
@import "atoms/details";
@import "atoms/overlay";
@import "atoms/only";
@import "atoms/checkout/total";

@import "molecules/button";
@import "molecules/link";
@import "molecules/linkGroup";
@import "molecules/brandList";
@import "molecules/searchForm";
@import "molecules/categoryTile";
@import "molecules/pagination";
@import "molecules/toggle";
@import "molecules/counter";
@import "molecules/product/review";
@import "molecules/product/productInfoTable";
@import "molecules/filterList";
@import "molecules/textTooltip";
@import "molecules/product/productImages";
@import "molecules/product/rating";
@import "molecules/buttonGroup";
@import "molecules/form/group";
@import "molecules/badged-icon";
@import "molecules/sliderArrow";
@import "molecules/carouselDots";
@import "molecules/labelSheet";
@import "molecules/gallery";

@import "molecules/checkout/progress";
@import "molecules/checkout/cart-summary";

@import "molecules/header/infoBar";
@import "molecules/header/navigation";
@import "molecules/header/mobileMenu";

@import "molecules/form/floating";
@import "molecules/form/rating";
@import "molecules/form/passwordInput";

@import "organisms/header";
@import "organisms/footer";
@import "organisms/advertisement";
@import "organisms/breadcrumbs";
@import "organisms/product/productPreview";
@import "organisms/product/productVariant";
@import "organisms/product/productVariantList";
@import "organisms/product/productPreviewList";
@import "organisms/product/productPreviewVariants";
@import "organisms/product/productPreviewVariantsList";
@import "organisms/content";
@import "organisms/product/productRatings";
@import "organisms/createRating";
@import "organisms/product/starCounter";
@import "organisms/product/productInfoList";
@import "organisms/product/reviews";
@import "organisms/product/productVariantPopUp";
@import "organisms/cart/side-cart-item";
@import "organisms/cart/side-cart-items";
@import "organisms/cart/quick-capture";
@import "organisms/accessoire";
@import "organisms/accessoireList";
@import "organisms/cartOverview";
@import "organisms/loginTile";
@import "organisms/product/comparableProduct";
@import "organisms/product/compareProducts";
@import "organisms/product-top";
@import "organisms/search-results";
@import "organisms/popUp/popUp";
@import "organisms/popUp/selectCheckoutType";
@import "organisms/popUp/newAddress";
@import "organisms/account-form";

@import "organisms/search-results";
@import "organisms/checkout/basketList";
@import "organisms/checkout/pages";

@import "organisms/customer/ordersList";
@import "organisms/customer/order";
@import "organisms/customer/productItem";
@import "organisms/customer/orderTable";

@import "pages/checkout";

@import "utilities/grids";

@import "cms/cmsText";

html {
  scroll-behavior: smooth;
  @apply max-md:text-[14px];
}

body {
  @apply overflow-x-hidden;
}
