@layer components {
  a:not(.button):not(.category):not(.link):not(.arrow):not(.product-preview__container),
  .menu__link {
    /* hover used for icon links in items list */
    @apply hover:text-primary-hover focus:text-primary-hover transition;
  }

  .link {
    @apply inline-flex gap-2 transition;
  }
  .link i {
    @apply transition;
  }

  .link--stretched {
    @apply inline-block after:absolute after:top-0 after:left-0 after:right-0 after:bottom-0 after:z-10;
  }

  .link--secondary-action {
    @apply text-secondary-action hover:text-primary;
  }
  a.link--accent,
  a.link--more,
  a.link--less,
  .link--back,
  .link--back2 {
    /* :not([]) is a workaround to rank higher than a:not(.button):not(.category) ... */
    @apply text-accent transition hover:text-accent-hover focus:text-accent-hover #{!important};
  }
  .link--less i {
    @apply rotate-180;
  }

  .link--back:before {
    content: var(--icon-ti-arrow-left);
    font-family: var(--icon-ti-font-family);
  }
  .link--back2:before {
    content: var(--icon-ti-arrow-back-up);
    font-family: var(--icon-ti-font-family);
  }

  .link--inline {
    @apply underline hover:text-primary-hover focus:text-primary-hover;
  }

  .link--light {
    @apply text-light hover:text-light-highlight focus:text-light-highlight;
  }
}
