@layer components {
    .price {
        @apply font-bold;
    }

    .price--discounted {
        @apply text-red;
    }

    .price__original {
        @apply block text-sm text-primary font-normal;
    }
}
