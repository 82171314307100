@layer components {
    .comparable-product {
        @apply min-w-[200px];
    }

    .comparable-product__product{
        @apply px-5 pt-5 border-b border-border;
    }

    .comparable-product.active {
        @apply bg-light-highlight;
    }

    .comparable-product__img-container {
        @apply w-full pb-100p relative;
    }

    .comparable-product__img-container img {
        @apply absolute w-full h-full object-contain;
    }

    .comparable-product__category {
        @apply mt-5;
    }

    .comparable-product__name {
        @apply -mt-1;
    }

    .comparable-product__price {
        @apply font-bold text-lg mt-2;
    }

    .comparable-product__rating {
        @apply mt-2 mb-3.5;
    }

    .comparable-product__compare_info {
        @apply py-2 border-b border-border pl-5;

        &:nth-child(2) { @apply pt-5; }
    }
}
