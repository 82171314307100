@layer components {
  .page-content,
  .page-title {
    @apply mx-4 xl:mx-side my-side block gap-32 max-md:px-4;
  }
  .page-content--with-sidebar {
    @apply block xl:grid xl:grid-cols-side-bar;
  }
  .page-content--sm {
    @apply w-full max-w-[1200px] mx-auto;
  }
  .page-content--login {
    @apply w-full max-w-[1260px] mx-auto;
  }

  .page-title {
    @apply block;
  }

  .page-content__main {
    /* for table-responsive to work
        @apply overflow-hidden; */
    @apply w-full;

    @media print {
      zoom: 75%;
    }
  }
}
