.counter {
    @apply flex flex-row;

  &.counter--invalid {
    .button,
    .form-control {
      @apply border-invalid;
    }
  }
}

.form-control.counter__input {
    @apply text-center border-x-0 rounded-none max-w-[3rem]
}

.counter .button {
    @apply w-12 px-0 rounded-none
}

.counter lbs-button:first-child .button {
    @apply rounded-l
}

.counter lbs-button:last-child .button {
    @apply rounded-r
}

input.lbs-quantity-input-field {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}
