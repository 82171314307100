@layer components {
  .table {
    @apply w-full text-left table-auto;
  }
  .table-responsive {
    @apply overflow-x-auto overflow-y-hidden;
  }

  .table--top tr {
    @apply align-top;
  }

  .table th {
    @apply font-normal text-sm;
  }

  .table tr {
    @apply border-b border-border;
  }

  /* bc: where is this needed? */
  /*.table tr:last-child {
        @apply border-none;
    }*/

  .table thead tr {
    @apply border-none;
  }

  .table td,
  .table th {
    @apply p-4;
  }

  .table td:first-child,
  .table th:first-child,
  .table lbs-table-header:first-child th {
    @apply pl-0;
  }

  .table lbs-table-header:not(:first-child) th:first-child {
    @apply pl-4;
  }

  .table td:last-child,
  .table th:last-child,
  .table lbs-table-header:last-child th {
    @apply pr-0 text-right;
  }

  .table lbs-table-header:not(:last-child) th:last-child {
    @apply pr-4 text-left;
  }

  .table--condensed {
    @apply mb-10;
  }

  .table--condensed tbody {
    @apply divide-none border-t border-border;
  }

  .table--condensed tbody tr {
    @apply border-none;
  }

  .table--condensed tr td {
    @apply p-1;
  }

  .table--condensed tr:first-child > td {
    @apply pt-4;
  }

  .table--condensed tr:last-child > td {
    @apply pb-4;
  }

  .table--condensed .table__total td {
    @apply font-bold border-y border-border py-4 mt-4;
  }

  .table--order-items,
  .table--checkout-items,
  .table--no-border {
    tr:last-child,
    tbody tr:last-child {
      @apply border-b-0;
    }
  }

  .table__numeric,
  .table__date,
  .table__actions--right {
    @apply text-right whitespace-nowrap;
  }
  .table__actions,
  .table__unit {
    @apply whitespace-nowrap;
  }

  .table .form-select {
    @apply w-auto;
  }

  .table--tooltip {
    @apply w-full;
  }
}
