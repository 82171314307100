@layer components {
    .total {
        @apply flex justify-between items-center border-y border-dashed border-border py-8;
    }

    .total__total {
        @apply max-md:text-lg whitespace-nowrap text-h3 font-bold;
    }

    .total h2 span,
    .total h3 span,
    .total h4 span,
    .total h5 span,
    .total h6 span {
        @apply text-sm font-normal;
    }

    .total h2,
    .total h3,
    .total h4,
    .total h5,
    .total h6,
    .total p {
        @apply mb-0;
    }
}
