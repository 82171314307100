@layer components {
    .grid--ads {
        @apply grid-rows-2 md:grid-rows-none grid-cols-12 gap-4;

        > :first-child { @apply col-span-12 md:col-span-7; }
        > :last-child { @apply col-span-12 md:col-span-5; }
    }

    .grid--ads-reverse {
        @apply grid-rows-2 md:grid-rows-none grid-cols-12 gap-4;

        > :first-child { @apply col-span-12 md:col-span-5; }
        > :last-child { @apply col-span-12 md:col-span-7; }
    }

    .grid--breadcrumbs {
        @apply flex flex-col md:flex-row justify-between;
    }

    .grid--cart {
        @apply flex gap-20 flex-wrap lg:flex-nowrap;
        > :first-child { @apply w-full lg:w-2/3; }
        > :last-child { @apply w-full lg:w-1/3; }
    }

    .grid--inputs-2 {
        @apply grid grid-cols-1 md:grid-cols-2 gap-x-8;
    }

    .grid--deletable {
        @apply flex justify-between;
    }

    .grid--addresses {
        @apply grid-cols-2 md:grid-cols-3;
    }

    .grid--dashboard {
        @apply grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-10;
    }
    .grid--order-meta {
        @apply md:grid-cols-2;

        > *:last-child { @apply md:text-right; }
    }

    .grid--form-nested {
        @apply flex gap-8;

        > * { @apply w-full; }
    }
}
