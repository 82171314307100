@layer components {
    .reviews-container__row {
        @apply flex flex-wrap gap-2.5 justify-between md:items-center pb-[0.8rem];
        @apply border-b-border border-b;
    }

    .reviews-container__row .heading-3 {
        @apply mb-0;
    }

    .reviews-container__list {
        @apply mb-[2.5rem];
    }

    .reviews-container .button--primary-link {
        @apply items-baseline;
    }
}