// SNACKBAR
.mat-mdc-snack-bar-container.success .mdc-snackbar__surface {
  @apply bg-valid text-light;
}

.mat-mdc-snack-bar-container.warning .mdc-snackbar__surface {
  @apply bg-warning text-base-font;
}

// DATEPICKER
.mat-calendar-body-selected {
  background-color: theme("colors.primary") !important;
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: theme("colors.primary-light") !important;
  }
}

.lbs-datepicker {
  mat-form-field {
    max-height: 42px;
    width: 100% !important;
  }

  .mat-calendar-body-cell-content,
  .mdc-text-field--filled {
    @apply rounded;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: theme("colors.transparent") !important;
  }

  .mat-calendar-body-active {
    .mat-calendar-body-today {
      color: theme("colors.white");
      background-color: theme("colors.accent") !important;
      @apply rounded;
    }
  }

  .mat-calendar-body-cell-content:hover {
    color: theme("colors.white") !important;
  }

  .mat-datepicker-content {
    @apply rounded;
    box-shadow: 0 0 0 0 !important;
    border: 1px solid theme("colors.border");
  }

  .mat-mdc-form-field-infix {
    min-height: auto !important;
  }

  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .mdc-text-field {
    padding: 0 0.625rem !important;
  }

  .mat-datepicker-input {
    font-family: theme("fontFamily.sans"), sans-serif !important;
    font-weight: 400 !important;
    color: theme("colors.black") !important;
    letter-spacing: 0 !important;
  }

  .mat-datepicker-toggle {
    .mat-mdc-icon-button.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 0 !important;
      padding: 0;
      right: 1.625rem !important;
    }
  }

  .mat-mdc-form-field-flex {
    position: relative;
  }

  .mat-mdc-form-field-icon-suffix {
    position: absolute !important;
    top: 0;
    right: 0;
    transform: translate(50%, 50%);
  }

  .mat-mdc-icon-button .mat-mdc-focus-indicator {
    display: none !important;
  }

  .mdc-text-field--filled {
    @apply rounded;
  }

  .mdc-text-field {
    border: 1px solid theme("colors.border");
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: theme("colors.white") !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: theme("colors.transparent") !important;
  }

  .mat-form-field:not(.mat-form-field-has-label) .mat-form-field-infix {
    border-top-width: 0;
  }

  .mdc-line-ripple {
    display: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: theme("colors.black") !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mdc-text-field--filled.mdc-text-field--disabled {
    @apply bg-light-highlight text-muted pointer-events-none;
  }

  .mat-mdc-form-field-infix {
    input::placeholder {
      color: theme("colors.muted") !important;
      letter-spacing: 0 !important;
    }

    input:disabled::placeholder {
      color: theme("colors.muted") !important;
    }
  }

  .mdc-text-field--invalid {
    border: 1px solid theme("colors.invalid") !important;
  }

  .mat-datepicker-toggle-active {
    color: theme("colors.primary") !important;
  }

  .lbs-datepicker--invalid .mdc-text-field {
    @apply border-invalid;
  }
}

// TOGGLE SWITCH
.lbs-toggle {
  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: theme("colors.accent");
    --mdc-switch-selected-handle-color: theme("colors.accent");
    --mdc-switch-selected-hover-state-layer-color: none !important;
    --mdc-switch-selected-pressed-state-layer-color: theme("colors.accent");
    --mdc-switch-selected-focus-handle-color: theme("colors.accent");
    --mdc-switch-selected-hover-handle-color: theme("colors.accent");
    --mdc-switch-selected-pressed-handle-color: theme("colors.accent");
    --mdc-switch-selected-focus-track-color: theme("colors.border");
    --mdc-switch-selected-hover-track-color: theme("colors.border");
    --mdc-switch-selected-pressed-track-color: theme("colors.border");
    --mdc-switch-selected-track-color: theme("colors.border");
  }

  .mdc-switch:enabled .mdc-switch__track::after,
  .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
  .mdc-switch:enabled:active .mdc-switch__track::after,
  .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
    background-color: theme("colors.accent");
    opacity: 0.5;
  }

  .mdc-switch__ripple {
    display: none;
  }

  .mdc-form-field > label {
    padding-left: 10px !important;
  }

  .mdc-switch.mdc-switch--selected .mdc-switch__icon {
    height: 14px !important;
    width: 14px !important;
  }

  .error-state .mdc-form-field label {
    color: theme("colors.invalid") !important;
  }
}

// CHECKBOX
.lbs-checkbox {
  .mdc-checkbox {
    padding: 0;
  }

  .mdc-checkbox__ripple {
    display: none;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
  .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
  .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
    border-color: theme("colors.primary");
    background-color: theme("colors.primary");
    top: 0;
    left: 0;
  }

  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background,
  .mdc-checkbox:not(:disabled):active
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background {
    border-color: theme("colors.primary");
  }

  .mdc-checkbox__background {
    @apply rounded-sm;
    border-width: 1px;
  }

  .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background {
    border-color: theme("colors.primary");
  }

  .mdc-label {
    font-size: theme("fontSize.base");
    font-family: theme("fontFamily.sans"), sans-serif;
    padding-left: 10px;
    letter-spacing: 0;
  }

  // CHECKBOX WITH ERROR
  &.lbs-checkbox--error {
    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
    .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
      border-color: theme("colors.invalid");
      background-color: theme("colors.invalid");
    }

    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
      ~ .mdc-checkbox__background {
      border-color: theme("colors.invalid");
    }

    .mdc-label {
      color: theme("colors.invalid");
    }
  }

  .mdc-form-field--align-end > .mdc-label {
    padding-left: 0;
    padding-right: 10px;
  }

  // DISABLED CHECKBOX
  &.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
    color: theme("colors.border");
  }

  .mdc-checkbox
    .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
    ~ .mdc-checkbox__background {
    border-color: theme("colors.border");
  }

  .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
  .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background {
    background-color: theme("colors.border");
  }
}

// FILTER CHECKBOX
lbs-filter-select {
  .lbs-checkbox .mdc-label {
    font-size: theme("fontSize.sm");
    color: theme("colors.black");
  }

  .lbs-checkbox,
  .mdc-form-field,
  .mdc-label {
    width: 100%;
  }
}

// RADIO-BUTTON
.mat-mdc-radio-button {
  .mdc-radio {
    padding: 0 !important;
  }

  .mdc-label {
    font-size: theme("fontSize.base");
    font-family: theme("fontFamily.sans"), sans-serif;
  }

  .mat-radio-ripple .mat-ripple-element,
  .mat-radio-ripple,
  .mdc-radio .mdc-radio__background::before {
    display: none !important;
  }

  .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
  .mdc-radio:hover .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio:hover .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
  .mdc-radio:hover .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: theme("colors.primary") !important;
  }

  .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: theme("colors.primary") !important;
  }

  // DISABLED RADIO-BUTTON
  .mdc-radio--disabled + label {
    color: theme("colors.border") !important;
    pointer-events: none !important;
  }

  .mdc-radio .mdc-radio__native-control:disabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio .mdc-radio__native-control:disabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
  .mdc-radio .mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
    opacity: 1;
    border-color: theme("colors.border") !important;
  }

  .mdc-form-field {
    @apply w-full;

    .mdc-label {
      @apply w-full;
    }
  }

  // ERROR RADIO-BUTTON
  &.lbs-radio-button--error {
    .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio:hover .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-radio:hover .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio:hover .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
    .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
      border-color: theme("colors.invalid") !important;
    }

    .mdc-label {
      color: theme("colors.invalid") !important;
    }
  }
}

.mdc-radio__outer-circle {
  border-width: 1px !important;
}

.mdc-form-field > label {
  cursor: pointer !important;
  padding-left: 10px !important;
}

.mdc-form-field--align-end > label {
  padding-left: 0 !important;
  padding-right: 10px !important;
}

.mdc-form-field {
  align-items: normal !important;
}

.mdc-radio {
  top: 4px !important;

  .mat-mdc-radio-touch-target {
    width: 16px !important;
    height: 16px !important;
  }

  .mdc-radio__native-control {
    width: 16px !important;
    height: 16px !important;
  }

  .mdc-radio__background {
    //top: -4px !important;

    width: 16px !important;
    height: 16px !important;

    .mdc-radio__inner-circle {
      left: -2px !important;
      top: -2px !important;
    }
  }
}

// TABLE
.mat-mdc-table {
  .mat-mdc-cell,
  .mat-mdc-header-cell {
    border-bottom-color: theme("colors.secondary");
    letter-spacing: 0;
  }

  .mat-mdc-row,
  .mat-mdc-header-row {
    font-size: theme("fontSize.base");
  }

  .mat-mdc-header-cell.cdk-header-cell {
    @apply uppercase text-sm font-bold bg-secondary;
  }

  .mat-mdc-cell {
    @apply text-sm font-normal;
  }

  .mat-column-total {
    @apply text-right;
  }
}
