@layer components {
    .form-control-group {
        @apply relative w-full;

        .form-control {
            @apply pr-[3rem] #{!important};
        }
    }

    .form-control-group__actions,
    .form-control-group__icons {
        @apply absolute top-1/2 right-0 p-0 -translate-y-1/2 flex items-center gap-2;
        i { @apply text-h3 text-input-icon; }
    }
    .form-control-group__icons {
        @apply right-3;
    }
}
