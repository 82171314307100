.icon:not(.icon-tabler),
.ti,
.im {
  font-size: 1.25em;
  display: inline-flex;
  align-items: center;
}

.icon--loading {
  cursor: unset;
  pointer-events: none;
  position: relative;

  svg {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    margin: 0 auto;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: theme("colors.muted") theme("colors.muted") theme("colors.muted") transparent;
    animation: loading-animation 1.5s linear infinite;
    cursor: unset;
    pointer-events: none;
  }
}

.button--accent-link .icon--loading:after {
  border-color: theme("colors.accent") theme("colors.accent") theme("colors.accent") transparent;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
