@layer components {
    .cards {
        > .card {
            @apply mb-paragraph;
        }
    }

    .card {
        @apply border border-border p-5 w-full;
    }

    .card {
        h6, .h6 { @apply m-0; }
    }

  .card__wrap {
    @apply shrink-0;
  }

    .card--highlighted {
        @apply bg-light-highlight p-5 w-full rounded border border-border;
    }

    .card--with-icon {
        @apply flex flex-row gap-5;
        .card__content { @apply overflow-x-hidden; }
    }
    .card--with-link {
        @apply flex flex-row gap-5 items-center justify-between;
        .card__content { @apply w-auto; }
    }

    .card--accordion {
        @apply p-0;
        .accordion__title { @apply font-normal; }
    }

    .card .form--inline,
    .card .form-floating {
        @apply w-full;
    }

    .card__icon {
        @apply bg-muted text-light flex items-center justify-center min-w-[3.125rem] min-h-[3.125rem] max-w-[3.125rem] max-h-[3.125rem] rounded-full text-h3;
    }

    .card__content > *:last-child {
        @apply mb-0;
    }

    .card__content {
        @apply w-full;
        h1, h2, h3, h4, h5, h6 { @apply mb-0; }
    }
}
