@layer components {
    .overlay {
        @apply fixed z-50 bg-primary opacity-25 top-0 left-0 right-0 bottom-0 hidden;
    }
    .overlay--content {
        @apply z-30;
    }
    .overlay--visible {
        @apply block;
    }
}
