@layer components {
    .badge {
        @apply rounded-sm py-0 px-1 text-sm inline-block;
    }

    .badge--lg {
        @apply text-h3 font-bold py-0 px-4 min-h-[40px] inline-flex items-center;
    }

    .badge--accent {
        @apply bg-accent text-light;
    }
    .badge--advertisement {
        @apply bg-red text-light text-center px-8 max-lg:px-2;
    }
    .badge--bordered-primary {
        @apply border border-primary;
    }
}
