@layer components {
  gallery {
    background-color: white !important;
  }

  lightbox gallery {
    padding: 10px !important;
  }

  lightbox gallery-counter {
    display: none;
  }

  g-image-item {
    padding: 30px !important;
  }

  .g-active-thumb {
    border: 2px solid theme("colors.primary");
  }

  gallery-thumb {
    gallery-image {
      opacity: 1 !important;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: black;
      display: none;
      opacity: 0.04;
    }

    &:hover {
      &:after {
        display: block;
      }
    }
  }

  .g-slider-content {
    gap: 10px !important;
  }

  gallery-thumbs {
    margin-top: 1.75rem;
  }

  lightbox {
    border-radius: 0 !important;
  }
}

.g-nav-next,
.g-nav-prev {
  background-color: theme("colors.grey");
  padding: 0;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: none !important;
  @apply transition-all opacity-100 lg:opacity-0 #{!important};

  &:hover {
    @apply shadow-default;
  }

  svg {
    fill: none !important;
    width: 20px !important;
    filter: none !important;
  }
}

.g-nav-prev svg {
  transform: perspective(1px) scale(-1);
}

.g-nav-next:hover {
  right: 0.5em !important;
}

.g-nav-prev:hover {
  left: 0.5em !important;
}

gallery-thumbs .g-slider[centralised="true"]:before {
  display: none !important;
}

gallery-slider:hover {
  .g-nav-next,
  .g-nav-prev {
    opacity: 1 !important;
  }
}
