@layer components {
  .product-header {
    @apply mb-16 gap-16;
    @apply grid grid-cols-1 lg:grid-cols-2;
  }

  .product-top__price {
    @apply font-bold mb-8;
  }

  .product-top__rating {
    @apply flex flex-row justify-between items-center;
    .rating-container {
      @apply mb-0;
    }
  }

  .product-top__add-to-cart {
    @apply flex flex-row items-start gap-4 flex-wrap;
  }

  .product-top__add-to-cart__action {
    @apply flex-1;
  }

  .product-top__add-to-cart__action .button {
    @apply min-w-[280px];
  }

  .product-top__add-to-cart__action,
  .product-top__add-to-cart__action .button {
    @apply w-full;
  }

  .product-top__options {
    @apply my-8;
  }
  .product-top__availabilty {
    @apply mt-8 mb-4 text-sm;
  }
  .product-top__title {
    @apply mb-2 break-words hyphens-auto;
    width: calc(100% - 60px);
  }

  .product-top__actions {
    @apply text-lg float-right;
  }
  .product-top__info > * {
    @apply mb-0;
  }
}
