@layer components {
    .form-checkbox {
        @apply relative inline-flex items-center;
    }
    .form-checkbox:checked {
        @apply bg-primary;
    }
    .form-checkbox__label {
        @apply pl-2;
    }

    .form-checkbox input {
        @apply absolute opacity-0 cursor-pointer h-0 w-0;
    }

    /* Create a custom checkbox */
    .checkmark {
        @apply relative h-4 w-4 bg-light border border-primary rounded-sm;
    }

    /* On mouse-over, add a grey background color */
    .form-checkbox:hover input ~ .checkmark {
        @apply bg-grey;
    }

    /* When the checkbox is checked, add a black background */
    .form-checkbox input:checked ~ .checkmark {
        @apply bg-primary;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        @apply absolute hidden;
        content: '';
    }

    /* Show the checkmark when checked */
    .form-checkbox input:checked ~ .checkmark:after {
        @apply block;
    }

    /* Style the checkmark/indicator */
    .form-checkbox .checkmark:after {
        @apply left-1 top-px w-1.5 h-2.5 border-r-2 border-b-2 border-light rotate-45;
    }
}