lbs-text-with-title {
  ol,
  ul {
    list-style: revert;
    padding: revert;
    margin: revert;
  }

  h1 {
    margin-bottom: revert;
  }
}
