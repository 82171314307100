@layer components {
  .carousel__dots {
    @apply flex justify-center gap-2 text-secondary text-tiny mt-8;
    .active {
      @apply text-primary;
    }
  }
}

.owl-dots {
  @apply flex justify-center gap-2 text-secondary text-tiny mt-8;

  span {
    width: 12px;
    height: 12px;
    @apply inline-block bg-secondary rounded-full;

    &:hover {
      @apply bg-primary-hover;
    }
  }

  .owl-dot.active span {
    @apply bg-primary;

    &:hover {
      @apply bg-primary-hover;
    }
  }
}

.owl-item.active:not(:last-child) {
  //@apply border-r border-border;
}
