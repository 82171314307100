@layer components {
    .accessoire-list {
        @apply relative;
    }

    .accessoires__items {
        @apply flex flex-row relative mb-2;
    }

    .accessoires__item {
        @apply w-full hidden;
    }

    .accessoires__item.active {
        @apply block;
    }
}