@layer components {
  table tr.product-variant--active {
    @apply border border-border border-b-0;
  }

  table tr.product-variant__active-row {
    @apply border-x border-border border-b-0;
  }

  tr:first-of-type.product-variant__active-row {
    border-top-width: 1px;
  }

  .product-variant-list tr:last-child.product-variant__active-details {
    border-bottom-width: 1px;
  }

  .product-variant__active-details {
    @apply border-x border-border;
  }

  .product-variant__article-number {
    @apply w-[17%] hidden md:table-cell print:table-cell;
  }

  .pop-up .product-variant__article-number {
    @apply md:hidden xl:table-cell;
  }

  .product-variant__size {
    @apply w-[14.5%];
  }

  .product-variant__color {
    @apply w-[25%];
  }

  .product-variant__unit {
    @apply w-[14.5%] text-right hidden md:table-cell;
  }

  .pop-up .product-variant__unit {
    @apply md:hidden xl:table-cell print:table-cell;
  }

  .product-variant__availability {
    @apply w-[14.5%] text-right;
  }

  .product-variant__price {
    @apply w-[14.5%] text-right hidden md:table-cell print:table-cell whitespace-nowrap;
  }

  .pop-up .product-variant__price {
    @apply md:hidden xl:table-cell;
  }

  .product-preview__active-details__image {
    @apply align-top;
    img {
      @apply w-full h-full max-w-thumbnail-preview-mobile max-h-thumbnail-preview-mobile object-contain;
    }
  }

  .product-preview__active-details {
    @apply flex flex-row flex-wrap justify-between mt-0 gap-6;
  }

  .product-preview__active-details__preview {
    @apply flex flex-row;
  }

  .product-preview__active-details__preview__img {
  }

  .product-preview__active-details__preview img {
    @apply w-[160px] h-40 object-contain;
  }

  .product-preview__active-details__preview__title {
    @apply font-bold max-w-[300px];
  }

  .product-preview__active-details__additional-info {
    @apply md:hidden w-full grid grid-cols-2;
  }
  .pop-up .product-preview__active-details__additional-info {
    @apply md:grid xl:hidden;
  }

  .product-preview__active-details__buy-options {
    @apply grow;
  }

  .product-preview__active-details__discount-info {
    @apply text-sm text-muted;
  }

  .product-preview__active-details__discount-info div {
    @apply text-right;
  }

  .product-preview__active-details__total {
    @apply flex flex-row justify-end mt-6;
  }

  .product-preview__active-details__total__text {
    @apply text-sm flex items-center mr-small;
  }

  .product-preview__active-details__total__number {
    @apply font-bold text-large;
  }

  .product-preview__active-details__buttons {
    @apply flex flex-row justify-end items-end w-full;

    .button-group:first-child {
      @apply mr-10;
    }
    .button-group:last-child {
      @apply ml-7;
    }
  }
  .product-preview__active-details__buttons__unit {
    @apply gap-5;
  }
}
