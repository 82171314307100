@layer components {
  .orders-list li {
    @apply border-b border-border py-7;
  }

  .orders-list p {
    @apply text-sm mb-0;
  }

  .orders-list__products {
    @apply flex items-center gap-4;
  }

  .orders-list__products img {
    @apply max-w-[80px] max-h-[75px];
  }

  .orders-list__meta {
    @apply flex gap-14 items-center;
  }

  .orders-list__content {
    @apply flex justify-between items-end flex-wrap gap-8;
  }
}
