@layer components {
    .breadcrumbs-container {
        @apply flex text-sm;
    }
    .breadcrumbs__before {
        @apply whitespace-nowrap;
    }
    .breadcrumbs__before:after {
        @apply pl-4 mr-4 text-border;
        content: "|";
    }
    .breadcrumbs {
        @apply inline-flex flex-wrap text-sm mb-section;
    }
    .breadcrumbs li {
        @apply mr-2;
    }
    .breadcrumbs li:after {
        @apply pl-2 text-border;
        content: var(--icon-ti-chevron-right);
        font-family: var(--icon-ti-font-family);
    }
    .breadcrumbs li:last-child:after {
        content: none;
    }
}
