@layer components {
    .search-results {
        @apply absolute left-0 right-0 px-8 py-4 bg-light z-40;
    }
    .search-results a {
        @apply text-primary #{!important};
    }
    .search-results h6 {
        @apply text-sm mb-1;
    }

    .search-results__items {
        @apply xl:mr-12;
    }
    .search-results__items a {
        @apply block relative py-1 hover:bg-light-highlight focus:bg-light-highlight transition-colors px-8 -ml-8;
    }
    .search-results__items--terms a > span {
        @apply text-muted pl-2;
    }
    .search-results__items--terms a:hover:after {
        @apply absolute right-1;
        content: var(--icon-ti-arrow-up-left);
        font-family: var(--icon-ti-font-family);
    }

    .search-results__items--products a {
        @apply flex items-start gap-4 -ml-4 px-4;
    }
    .search-results__items--products {
        @apply -mr-8;
    }

    .search-results__items--products img {
        @apply w-[90px] h-[90px] object-contain;
    }
    .search-results__items__product {
        @apply overflow-hidden;
        p { @apply hyphens-auto break-words; }
    }
    .search-results__items__product *:last-child {
        @apply mb-0;
    }
}
