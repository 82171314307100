@layer components {
    .rating-container {
        @apply flex gap-2;
    }
    .rating__text {
        @apply text-sm text-muted;
    }

    .rating {
        @apply relative inline-block;
    }
    .rating__value,
    .rating__total {
        @apply flex;
    }
    .rating__value {
        @apply absolute left-0 top-0 overflow-hidden;
    }
    .rating__total {
        @apply text-secondary;
    }
}
