.form-rating {
    @apply flex flex-row text-[1.75rem];
}
.form-rating span {
    @apply text-muted cursor-pointer;
}
.form-rating span.form-rating__selected {
    @apply text-star;
}


