@layer components {
    .basket__items {
        @apply divide-y divide-border border-t border-b border-border mb-6;
    }

    .basket__item {
        @apply text-sm list-none flex gap-10 w-full pt-5 pb-7;
    }

    .basket__item__image {
        @apply hidden sm:flex items-center justify-center;
        img { @apply max-w-thumbnail-preview-mobile; }
    }

    .basket__item__main {
        @apply w-full flex flex-col;
    }

    .basket__item__inner {
        @apply flex justify-between items-end flex-wrap w-full gap-4;
    }

    .basket__item__pricing {
        @apply flex w-full xl:max-w-[50%] justify-between items-center gap-4;
        .price { @apply text-right; }
    }

    .basket__item__select {
        @apply flex items-center;
    }
}