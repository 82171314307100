.product-grid-skeleton__container {
  @apply p-4 #{!important};

  .product-grid-skeleton__image .skeleton-loader {
    @apply h-[250px] mb-8 rounded-xl #{!important};
  }

  .product-grid-skeleton__name .skeleton-loader {
    @apply h-[20px] rounded-xl #{!important};
  }

  .product-grid-skeleton__price .skeleton-loader {
    @apply h-[20px] w-2/3 rounded-xl #{!important};
  }

  .product-grid-skeleton__button {
    .skeleton-loader {
      @apply h-[40px] rounded-xl #{!important};
    }
  }
}

.skeleton-loader {
  @apply bg-light-highlight #{!important};
}
