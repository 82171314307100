@layer components {
  .options {
    @apply flex flex-col gap-8 mb-paragraph;
  }

  .option h6 {
    @apply font-normal mb-2;
  }

  .option__items {
    @apply flex flex-row flex-wrap gap-2;

    &.option__items--with-images .option__item {
      @apply w-option-with-image h-option-with-image justify-between overflow-hidden px-3;
      .option__item__label {
        @apply truncate text-ellipsis max-w-full;
      }
    }
  }

  .option__item,
  .option__item--active {
    &:after {
      content: "";
      @apply block absolute top-0 right-0 bottom-0 left-0 rounded border border-border hover:border-primary focus:border-primary hover:border-[2px] focus:border-[2px];
    }
  }

  .option__item {
    @apply relative flex flex-col items-center rounded text-center py-1 px-6 cursor-pointer;
  }

  .option__item--active {
    &:after {
      @apply border-primary border-[2px];
    }
  }

  .option__item img {
    @apply w-auto h-full;
    max-height: 64px;
  }
}
