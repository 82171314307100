@layer components {
    .product-ratings {
        @apply flex flex-col gap-y-12;
    }

    .product-ratings__average {
        @apply text-h3;
        span { @apply font-bold; }
    }

    .product-ratings__header .rating-container {
        @apply mb-8;
    }

    .product-ratings__header {
        @apply grid grid-cols-1 md:grid-cols-2;
    }
}