@layer components {
    .content-separator {
        @apply relative text-center;
    }
    .content-separator::before {
        content: "";
        @apply border-b border-border absolute left-0 right-0 top-[50%];
    }
    .content-separator span {
        @apply bg-[#fff] relative px-5;
    }

    .content-separated {
        @apply flex flex-col gap-8;
    }
}
