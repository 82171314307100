@layer components {
    .checkout__action .button {
        @apply sm:min-w-[300px];
    }

    .checkout__coupon {
        @apply flex-wrap;
    }
    .checkout__coupon .form-floating {
        @apply min-w-full sm:min-w-[200px] flex-1;
    }

    .checkout__payment__methods .grid--inputs {
        @apply flex flex-col gap-4;
    }
    .checkout__payment__card .form-group {
        @apply mb-3;
    }
}