@layer components {
    .login {
        @apply border border-border grid grid-cols-1 md:grid-cols-2;
    }

    .login-tile {
        @apply p-14 flex flex-col justify-end;
    }
    .login-tile h2,
    .login-tile h3,
    .login-tile h4,
    .login-tile h5,
    .login-tile h6 {
        @apply text-center pb-8 mb-auto;
    }

    .login-tile--highlighted {
        @apply bg-light-highlight
    }
    .login-tile__submit {
        @apply mt-10 md:mt-32 flex justify-center;
    }
    .login-tile__submit .button {
        @apply w-full max-w-[300px];
    }
}
