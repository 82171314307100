@layer components {
    .items {
        @apply relative grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-y-14 divide-x divide-border list-none;
        @apply auto-rows-fr;
    }

    .items:before {
        /* this exists to hide unnecessary dividers */
        @apply absolute left-0 top-0 bottom-0 w-[1px] bg-light;
        content: "";
    }

    .items--list {
        @apply grid-cols-1 divide-y divide-x-0 gap-y-0;
    }
    .items--list:before {
        content: none;
    }

    .items--carousel {
        @apply flex items-stretch flex-nowrap overflow-hidden; /* quick and dirty max width */
    }
    .items--products-4 {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-3 2xl:grid-cols-4 md:my-16 max-md:gap-0 max-md:divide-y max-md:divide-border;
    }
    .items--products-3 {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:my-16 max-md:gap-0 max-md:divide-y max-md:divide-border;
    }
}