@layer base {
    p {
        @apply mb-paragraph;
    }
}

@layer components {
    .text--with-icon {
        @apply inline-flex items-center justify-center gap-2;
    }
}
