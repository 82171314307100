@layer components {
    .availability {
        @apply inline-flex items-center justify-center cursor-help;

        &:after {
            @apply rounded-full text-center text-primary;
            font-family: var(--icon-ti-font-family);
        }
    }

    .availability--available:after {
        @apply text-valid;
        content: var(--icon-ti-circle-check-filled);
    }
    .availability--discontinued:after {
        @apply text-valid;
        content: var(--icon-ti-circle-half-2);
    }
    .availability--procurement:after {
        @apply text-star;
        content: var(--icon-ti-circle-filled);
    }
    .availability--unavailable:after {
        @apply text-danger;
        content: var(--icon-ti-circle-filled);
    }
    .availability--alternative:after {
        content: var(--icon-ti-alert-circle-filled);
    }
}
