@layer components {
  .product-info-table__row {
    @apply flex flex-row border-b border-b-border py-1 first:pt-0;
  }

  .product-info-table__title {
    @apply w-full max-w-[300px];
  }

  .product-info-table__info {
    @apply text-muted w-full;
  }

  .product-info-table {
    @apply mb-paragraph max-w-[700px];
  }
}
