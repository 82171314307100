.text-tooltip {
    @apply relative my-auto
}

.text-tooltip:hover .text-tooltip__tooltip-container{
    @apply absolute left-full block pl-4 -mt-4
}

.text-tooltip__tooltip-container {
    @apply hidden w-80 left-full top-0 z-10;
}

.text-tooltip__tooltip-container__tooltip {
    @apply break-words w-full h-auto min-h-fit p-2 rounded border border-border bg-light text-sm
}

.text-tooltip__tooltip-container__tooltip::after {
    @apply absolute border-solid block w-0 top-[13px] left-[4.5px] bottom-auto border-transparent-white border-r-light;
    content: "";
    border-width: 8px 13px 8px 0;
}

.text-tooltip__tooltip-container__tooltip::before {
    @apply absolute border-solid block w-0 top-3 left-[3px] bottom-auto border-transparent-muted border-r-border;
    content: "";
    border-width: 9px 14px 9px 0;
}