@layer components {
    .products-list .section-title h2 {
        @apply font-bold;
    }

    .product-preview__list--carousel {
        @apply relative;

        .arrow {
            @apply lg:invisible;
        }
        &:hover .arrow {
            @apply visible;
        }
        @media (hover: none) {
            .arrow { @apply visible #{!important}; }
        }
    }
}
